/** разбивает строку на пробелы - сумма 300000 => 300 000**/
type numberWithCommasType = (x:number | string) => string;
export const numberWithCommas:numberWithCommasType = (x) => {
  if ( x === 0 ) return '0';
  if (!x ) return '0';
  if ( !(typeof x ==='number' || typeof x === 'string') ) return '0';

  if (typeof x === 'number') {
    x = x.toString();
  }
  return x.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};


export function transliterate(word: any): string{
  let answer = ''
    , a: any = {};

  a["Ё"]="YO";a["Й"]="I";a["Ц"]="TS";a["У"]="U";a["К"]="K";a["Е"]="E";a["Н"]="N";a["Г"]="G";a["Ш"]="SH";a["Щ"]="SCH";a["З"]="Z";a["Х"]="H";a["Ъ"]="'";
  a["ё"]="yo";a["й"]="i";a["ц"]="ts";a["у"]="u";a["к"]="k";a["е"]="e";a["н"]="n";a["г"]="g";a["ш"]="sh";a["щ"]="sch";a["з"]="z";a["х"]="h";a["ъ"]="'";
  a["Ф"]="F";a["Ы"]="I";a["В"]="V";a["А"]="a";a["П"]="P";a["Р"]="R";a["О"]="O";a["Л"]="L";a["Д"]="D";a["Ж"]="ZH";a["Э"]="E";
  a["ф"]="f";a["ы"]="i";a["в"]="v";a["а"]="a";a["п"]="p";a["р"]="r";a["о"]="o";a["л"]="l";a["д"]="d";a["ж"]="zh";a["э"]="e";
  a["Я"]="Ya";a["Ч"]="CH";a["С"]="S";a["М"]="M";a["И"]="I";a["Т"]="T";a["Ь"]="'";a["Б"]="B";a["Ю"]="YU";
  a["я"]="ya";a["ч"]="ch";a["с"]="s";a["м"]="m";a["и"]="i";a["т"]="t";a["ь"]="'";a["б"]="b";a["ю"]="yu";

  for (const i in word){
    if (word.hasOwnProperty(i)) {
      if (a[word[i]] === undefined){
        answer += word[i];
      } else {
        answer += a[word[i]];
      }
    }
  }
  return answer;
}

/** клонирует объект **/
type CloneObjType = <T>(obj:T) => T;
export const cloneObj:CloneObjType = (obj) => {
  return JSON.parse( JSON.stringify(obj) );
};

export const roundTwo = (sum: number) => {
  return Math.floor(sum * 100) / 100;
}

export function download(filename: string, text: string) {
  const element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}