import React, { useState } from 'react';
import store  from '../../store/store';
import { observer } from 'mobx-react-lite';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@mui/material';

interface IProps {}
export const FishPrice: React.FC<IProps> = observer((props) => {
  const [isShow, setIsShow] = useState(true);

  const content = () => {
    return <Grid container spacing={2}>
      <Grid item xs={3}>
        <TextField
          required
          type='number'
          id="FishPriceL-1"
          label="lvl 1"
          defaultValue={store.price_l1}
          onChange={e => store.setPrice_l1(parseInt(e.target.value))}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          required
          type='number'
          id="FishPriceL-2"
          label="lvl "
          defaultValue={store.price_l2}
          onChange={e => store.setPrice_l2(parseInt(e.target.value))}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          required
          type='number'
          id="FishPriceL-3"
          label="lvl 3"
          defaultValue={store.price_l3}
          onChange={e => store.setPrice_l3(parseInt(e.target.value))}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          required
          type='number'
          id="FishPriceL-4"
          label="lvl 4"
          defaultValue={store.price_l4}
          onChange={e => store.setPrice_l4(parseInt(e.target.value))}
        />
      </Grid>
    </Grid>
  }
  return (
    <>
      <Typography variant="h4" gutterBottom>
        Стоимость каждой группы рыб

        <FormControl style={{paddingLeft: '20px'}}>
          <RadioGroup
            row
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="show"
            name="radio-buttons-group"
          >
            <FormControlLabel value="show" control={<Radio  onChange={() => setIsShow(true)} />} label="Показать" />
            <FormControlLabel value="hide" control={<Radio onChange={() => setIsShow(false)} />} label="Скрыть" />
          </RadioGroup>
        </FormControl>
      </Typography>

      { isShow && content()}
    </>
  );
});
