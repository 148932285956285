import { makeAutoObservable } from 'mobx';


class Store {

  price_l1 = 116640;
  price_l2 = 279920;
  price_l3 = 699920;
  price_l4 = 1399920;

  count_fish_l1 = 0;
  count_fish_l2 = 0;
  count_fish_l3 = 0;
  count_fish_l4 = 0;

  resFishCost1 = 0;
  resFishCost2 = 0;
  resFishCost3 = 0;
  resFishCost4 = 0

  resFishCostAll = 0;

  constructor() {
    makeAutoObservable(this);
  }


  setPrice_l1(price: number) {
    this.price_l1 = price;
    this.updateResFish1();
  }
  setPrice_l2(price: number) {
    this.price_l2 = price;
    this.updateResFish2();
  }
  setPrice_l3(price: number) {
    this.price_l3 = price;
    this.updateResFish3();
  }
  setPrice_l4(price: number) {
    this.price_l4 = price;
    this.updateResFish4();
  }

  setCountFish_l1(count: number) {
    this.count_fish_l1 = count;
    this.updateResFish1();
  }
  setCountFish_l2(count: number) {
    this.count_fish_l2 = count;
    this.updateResFish2();
  }
  setCountFish_l3(count: number) {
    this.count_fish_l3 = count;
    this.updateResFish3();
  }
  setCountFish_l4(count: number) {
    this.count_fish_l4 = count;
    this.updateResFish4();
  }

  updateResFish1() {
   this.resFishCost1 = this.count_fish_l1 * this.price_l1;
   this.updateResFishAll();
  }
  updateResFish2() {
   this.resFishCost2 = this.count_fish_l2 * this.price_l2;
   this.updateResFishAll();
  }
  updateResFish3() {
   this.resFishCost3 = this.count_fish_l3 * this.price_l3;
   this.updateResFishAll();
  }
  updateResFish4() {
   this.resFishCost4 = this.count_fish_l4 * this.price_l4;
   this.updateResFishAll();
  }
  updateResFishAll() {
   this.resFishCostAll = this.resFishCost1 + this.resFishCost2 + this.resFishCost3 + this.resFishCost4;
  }
}
export default new Store();