import React, { useState, useEffect } from 'react';
import store  from '../../store/store';
import { observer } from 'mobx-react-lite';
import { download, numberWithCommas, roundTwo } from '../../utils';
import {
  Button,
  Grid, Stack,
  TextField,
  Typography
} from '@mui/material';

const keyInitPrice = 260000;

interface IProps {}
export const FishCost: React.FC<IProps> = observer((props) => {
  const [rog, setRog] = useState(0);
  const [pt, setPt] = useState(0);
  const [resPt, setResPt] = useState(0);

  const [keyPrice, setKeyPrice] = useState(keyInitPrice);
  const [countKey, setCountKey] = useState(1);

  useEffect(() => {
    const ptInit = store.resFishCostAll / 5000000;
    const ptRes = ptInit - (rog * 2);
    setPt(Math.floor(ptInit * 100) / 100)
    setPt(roundTwo(ptInit))

    setResPt(ptRes);
  }, [ store.resFishCostAll, rog ]);


  const getContentForDownload = (title: string): string => {
    function item(lvl: number, count: number, price: number, resFishCost: number) {
      return `Уровень ${lvl}.\n\tКоличество: ${count}. \n\tЦена ${numberWithCommas(price)}. \n\tИтого: ${numberWithCommas(resFishCost)} gold \n`
    }
    let res = 'Количество и цена рыбы: ' + title + '\n\n';
    res += item(1, store.count_fish_l1, store.price_l1, store.resFishCost1);
    res += item(2, store.count_fish_l2, store.price_l2, store.resFishCost2);
    res += item(3, store.count_fish_l3, store.price_l3, store.resFishCost3);
    res += item(4, store.count_fish_l4, store.price_l4, store.resFishCost4);

    res += `\n\nОбщая стоимость ${ numberWithCommas(store.resFishCostAll)} gold (${pt} PT) \n`;
    return res;
  };
  const downloadChange = () => {
    const date = new Date();

    let name = `${date.getFullYear()}.${date.getMonth()}.${date.getDate()}`;
    name += `_${date.getHours()}-${date.getMinutes()}-${date.getSeconds()}`;
    download('fish-' + name + '.txt', getContentForDownload(name));
  }
  return (
    <div style={{marginTop: '20px'}}>
      <div className='box'>
        <Typography   variant="h2" gutterBottom>  Стоимость рыбы</Typography>

        <Typography variant="h3" align="center" gutterBottom>
          { numberWithCommas(store.resFishCostAll)} <small>gold</small> ({pt} <small>PT</small>)
        </Typography>

        <br/>
        <Grid container spacing={5}>
          <Grid item xs={3}>
            <Typography variant="h6" align="center" gutterBottom>1 lvl</Typography>
            { store.count_fish_l1 } шт. * { numberWithCommas(store.price_l1) } gold
            <br/>
            <Typography variant="h6" align="center" gutterBottom>{ numberWithCommas(store.resFishCost1) } gold</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="h6" align="center" gutterBottom>2 lvl</Typography>
            { store.count_fish_l2 } шт. * { numberWithCommas(store.price_l2) } gold <br/>
            <Typography variant="h6" align="center" gutterBottom>{ numberWithCommas(store.resFishCost2) } gold</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="h6" align="center" gutterBottom>3 lvl</Typography>
            { store.count_fish_l3 } шт. * { numberWithCommas(store.price_l3) } gold <br/>
            <Typography variant="h6" align="center" gutterBottom>{ numberWithCommas(store.resFishCost3) } gold</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="h6" align="center" gutterBottom>4 lvl</Typography>
            { store.count_fish_l4 } шт. * { numberWithCommas(store.price_l4) } gold <br/>
            <Typography variant="h6" align="center" gutterBottom>{ numberWithCommas(store.resFishCost4) } gold</Typography>
          </Grid>
        </Grid>


        { store.resFishCostAll > 0 && <div style={{marginTop: '30px'}}>
          <Button onClick={() => downloadChange()} variant="contained">Скачать результат</Button>
        </div>}
      </div>




      <br/>
      <br/>

      <div>
        <Typography variant="h3" gutterBottom>Кол-во "рог изобилия"</Typography>

        <TextField
          required
          id="Rog"
          type="number"
          label='рог изобилия'
          defaultValue={rog || ''}
          onChange={e => setRog(parseInt(e.target.value))}
        />

        {rog > 0 &&
          <span className='rogRes'>
            рыба - рог = {roundTwo(resPt)} pt
          </span>
        }
      </div>

      <br/>
      <div className='box uha'>
        <Typography   variant="h4" gutterBottom>  Стоимость ухи</Typography>

        <Grid container spacing={5}>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>Охотничья Уха - 1 011 999</Typography>
            Окунь, Кета, Терпуг
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>Шаманская Уха - 1 294 399</Typography>
            Жерех, Скумбрия 2шт., Корюшка - 2 шт.
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>Целебная Уха - 1 402 399</Typography>
            Сайда - 3шт., Красный Берикс, Минтай
          </Grid>
        </Grid>
      </div>
      <br/>

      <div>
        <Typography variant="h3" gutterBottom>Ключи и стоимость</Typography>


        <TextField
          required
          id="Rog"
          label='стоимость ключа'
          defaultValue={keyPrice || ''}
          onChange={e => setKeyPrice(parseInt(e.target.value))}
        />


        <TextField
          label='Кол-во'
          style={{width: '110px', marginLeft: '15px'}}
          type="number"
          id="FishCount-2"
          defaultValue={countKey || ''}
          onChange={e => setCountKey(parseInt(e.target.value))}
        />



        <span className='keyRes'>
          = { numberWithCommas(keyPrice * countKey) } <small>gold</small> ({ keyPrice * countKey / 5000000 } pt)
        </span>
      </div>

    </div>
  );
});
