import React, { useState } from 'react';
import store from '../../store/store';
import { observer } from 'mobx-react-lite';
import { cloneObj, transliterate } from '../../utils';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid, List, ListItem,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@mui/material';

const createItem = (name: string) => {
  return {
    'code': transliterate(name),
    'name': name,
    'count': 0
  }
}

interface IProps {}
export const FishCount: React.FC<IProps> = observer((props) => {
  const [l1, setL1] = useState([
    createItem('Сайда'),
    createItem('Берш'),
    createItem('Атлантическая Сельдь'),
    createItem('Корюшка')
  ]);
  const [l2, setL2] = useState([
    createItem('Треска'),
    createItem('Окунь'),
    createItem('Скумбрия'),
    createItem('Терпуг')
  ]);
  const [l3, setL3] = useState([
    createItem('Минтай'),
    createItem('Красный Берикс'),
    createItem('Кета'),
    createItem('Жерех')
  ]);
  const [l4, setL4] = useState([
    createItem('Горбуша'),
    createItem('Навага'),
    createItem('Зубатка'),
    createItem('Серая Песчаная Акула')
  ]);

  const [toggleAll, setToggleAll] = useState(true);


  const toggleL1 = (val: string, code: string) => {
    let res = 0;
    const newState = cloneObj(l1).map(el => {
      if (code === el.code) {
        el.count = parseInt(val);
      }
      res += el.count;
      return el;
    });
    setL1(newState);
    store.setCountFish_l1(res);
  }
  const toggleL2 = (val: string, code: string) => {
    let res = 0;
    const newState = cloneObj(l2).map(el => {
      if (code === el.code) {
        el.count = parseInt(val);
      }
      res += el.count;
      return el;
    });
    setL2(newState);
    store.setCountFish_l2(res);
  }
  const toggleL3 = (val: string, code: string) => {
    let res = 0;
    const newState = cloneObj(l3).map(el => {
      if (code === el.code) {
        el.count = parseInt(val);
      }
      res += el.count;
      return el;
    });
    setL3(newState);
    store.setCountFish_l3(res);
  }
  const toggleL4 = (val: string, code: string) => {
    let res = 0;
    const newState = cloneObj(l4).map(el => {
      if (code === el.code) {
        el.count = parseInt(val);
      }
      res += el.count;
      return el;
    });
    setL4(newState);
    store.setCountFish_l4(res);
  }

  const moreNumbers = () => {
    return  <Grid container spacing={5}>
      <Grid item xs={3}>
        <Typography variant="h6" align="center" gutterBottom>1 lvl</Typography>
        <List sx={{ width: '100%',  bgcolor: 'background.paper' }}>
          { l1.map(el => {
            return <ListItem key={el.code}>
              <TextField
                type="number"
                id={`FishCount-1-${el.code}`}
                label={ el.name }
                defaultValue={el.count || ''}
                onChange={e => toggleL1(e.target.value, el.code)}
              />
            </ListItem>
          })}
        </List>
        <Typography  align="center" variant="h6" gutterBottom>
          Итого (1 lvl)  = {store.count_fish_l1}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography  align="center" variant="h6" gutterBottom>2 lvl</Typography>
        <List>
          { l2.map(el => {
            return <ListItem key={el.code}>
              <TextField
                type="number"
                id={`FishCount-2-${el.code}`}
                label={ el.name }
                defaultValue={el.count || ''}
                onChange={e => toggleL2(e.target.value, el.code)}
              />
            </ListItem>
          })}
        </List>
        <Typography align="center" variant="h6" gutterBottom>
          Итого (2 lvl)  = {store.count_fish_l2}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography align="center" variant="h6" gutterBottom>3 lvl</Typography>
        <List>
          { l3.map(el => {
            return <ListItem key={el.code}>
              <TextField
                type="number"
                id={`FishCount-3-${el.code}`}
                label={ el.name }
                defaultValue={el.count || ''}
                onChange={e => toggleL3(e.target.value, el.code)}
              />
            </ListItem>
          })}
        </List>
        <Typography align="center" variant="h6" gutterBottom>
          Итого (3 lvl)  = {store.count_fish_l3}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography align="center" variant="h6" gutterBottom>4 lvl</Typography>
        <List>
          { l4.map(el => {
            return <ListItem key={el.code}>
              <TextField
                type="number"
                id={`FishCount-4-${el.code}`}
                label={ el.name }
                defaultValue={el.count || ''}
                onChange={e => toggleL4(e.target.value, el.code)}
              />
            </ListItem>
          })}
        </List>
        <Typography align="center" variant="h6" gutterBottom>
          Итого (4 lvl)  = {store.count_fish_l4}
        </Typography>
      </Grid>
    </Grid>
  }

  const allNumbers = () => {
    return <Grid container spacing={2}>
      <Grid item xs={3}>
        <List sx={{ width: '100%',  bgcolor: 'background.paper' }}>
          <ListItem>
            <TextField
              type="number"
              id="FishCountAll-1"
              label='lvl 1'
              defaultValue={store.count_fish_l1}
              onChange={e => store.setCountFish_l1(parseInt(e.target.value))}
            />
          </ListItem>
        </List>
      </Grid>
      <Grid item xs={3}>
        <List sx={{ width: '100%',  bgcolor: 'background.paper' }}>
          <ListItem >
            <TextField
              type="number"
              id="FishCountAll-2"
              label='lvl 2'
              defaultValue={store.count_fish_l2}
              onChange={e => store.setCountFish_l2(parseInt(e.target.value))}
            />
          </ListItem>
        </List>
      </Grid>
      <Grid item xs={3}>
        <List sx={{ width: '100%',  bgcolor: 'background.paper' }}>
          <ListItem>
            <TextField
              type="number"
              id="FishCountAll-3"
              label='lvl 3'
              defaultValue={store.count_fish_l3}
              onChange={e => store.setCountFish_l3(parseInt(e.target.value))}
            />
          </ListItem>
        </List>
      </Grid>
      <Grid item xs={3}>
        <List sx={{ width: '100%',  bgcolor: 'background.paper' }}>
          <ListItem >
            <TextField
              type="number"
              id="FishCountAll-4"
              label='lvl 4'
              defaultValue={store.count_fish_l4}
              onChange={e => store.setCountFish_l4(parseInt(e.target.value))}
            />
          </ListItem>
        </List>
      </Grid>
    </Grid>
  }

  return <div style={{ marginTop: '20px'}}>
    <Typography variant="h2" gutterBottom>
      Количество рыбы
    </Typography>
    <FormControl >
      <FormLabel id="demo-radio-buttons-group-label">Ввод кол-во рыбы</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="every"
        name="radio-buttons-group"
      >
        <FormControlLabel value="every" control={<Radio onChange={() => setToggleAll(true)} />} label="каждая рыбка" />
        <FormControlLabel value="group" control={<Radio onChange={() => setToggleAll(false)} />} label="группа рыб" />
      </RadioGroup>
    </FormControl>

    { toggleAll ? moreNumbers() : allNumbers()}
  </div>

});
