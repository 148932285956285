import React from 'react';
import { FishPrice } from './FishPrice';
import { FishCount } from './FishCount';
import { FishCost } from './FishCost';

interface IProps {}
export const Fish: React.FC<IProps> = (props) => {
  return (
    <>
      <FishPrice />

      <FishCount />

      <FishCost />
    </>
  );
};
