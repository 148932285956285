import React from 'react';
import './App.css';
import { Fish } from './components/fish/Fish';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { TranslatedError } from './components/TranslatedError';

function App() {
  return (
    <div className="App">
      <TranslatedError />
      <Fish />
    </div>
  );
}

export default App;
