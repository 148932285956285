import React from 'react';

interface IProps {}
export const TranslatedError: React.FC<IProps> = (props) => {
  return (
    <div className='translated_error'>
      <div className="translated_error__body">
        <h1>Вам нужно выключить авто переводчик страницы</h1>
        <h2>С переводом, калькулятор не будет работать</h2>
      </div>
    </div>
  );
};
